table {
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td,
th{
  padding: 0.5em;
}
.table--body>tbody>tr:nth-child(even){
  background-color: rgba(14,30,37,.02);
}

td>a{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
