/***** 3rd Party Components *****/
@import "lib/normalize-css/normalize";
@import "lib/bourbon/bourbon";
@import "lib/neat/neat";
@import "base/base";

/***** Custom Layout Styles *****/
@import "layout";

@font-face {
  font-family: 'Rijksmuseum';
  src: local('Rijksmuseum'), url(../fonts/Rijksmuseum/Rijksmuseum-Normal.otf);
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(../fonts/OpenSans/OpenSans-Normal.ttf);
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Medium.ttf);
}


@font-face {
  font-family: 'OpenSans-Light';
  src: local('OpenSans-Light'), url(../fonts/OpenSans/OpenSans-Light.ttf);
}


@font-face {
  font-family: 'Arial';
  src: local('Arial'), url(../fonts/Arial/Arial-Normal.ttf);
}

@font-face {
  font-family: 'BebasNeue';
  src: local('BebasNeue'), url(../fonts/BebasNeue/BebasNeue-Normal.otf);
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url(../fonts/Calibri/Calibri-Normal.ttf);
}


@font-face {
  font-family: 'NeoTechStd';
  src: local('NeoTechStd'), url(../fonts/NeoTechStd/NeoTechStd-Normal.otf);
}

@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'), url(../fonts/Helvetica/Helvetica-Normal.otf);
}

@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'), url(../fonts/MuseoSans/MuseoSans-500.otf);
}

@font-face {
  font-family: 'MuseoSans-900';
  src: local('MuseoSans-900'), url(../fonts/MuseoSans/MuseoSans-900.otf);
}

@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Fira sans';
  src: url(../fonts/Firasans/FiraSans-Regular.ttf);
}

@font-face {
  font-family: 'Fira sans';
  src: url(../fonts/Firasans/FiraSans-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Museo';
  src: url(../fonts/Museo/Museo300-Regular.otf);
}

@font-face {
  font-family: 'Museo-700';
  src: url(../fonts/Museo/Museo700-Regular.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Asap';
  src: url(../fonts/Asap/Asap-Regular.ttf);
}

@font-face {
  font-family: 'Asap';
  src: url(../fonts/Asap/Asap-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Asap';
  src: url(../fonts/Asap/Asap-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url(../fonts/Campton/Campton-Book.otf);
}

@font-face {
  font-family: 'Campton';
  src: url(../fonts/Campton/Campton-SemiBold.otf);
  font-weight: bold;
}

@font-face {
  font-family: 'Campton';
  src: url(../fonts/Campton/Campton-SemiBoldItalic.otf);
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: 'EBGaramond';
  src: url(../fonts/EBGaramond/EBGaramond-Regular.ttf);
}

@font-face {
  font-family: 'EBGaramond';
  src: url(../fonts/EBGaramond/EBGaramond-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'EBGaramond';
  src: url(../fonts/EBGaramond/EBGaramond-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'EBGaramond';
  src: url(../fonts/EBGaramond/EBGaramond-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf);
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url(../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.ttf);
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf);
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Black.ttf);
}



