$_button-background-color: $action-color;
$_button-background-color-hover: lighten($action-color, 5%);
$_button-background-color-active: darken($action-color, 5%);

$_button-background-color-danger-hover: lighten($danger-red, 5%);
$_button-background-color-danger-active: darken($danger-red, 5%);

#{$all-buttons},
.button {
  appearance: none;
  background-color: $_button-background-color;
  border: 0;
  border-radius: $base-border-radius;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  letter-spacing: .02em;
  line-height: 1;
  padding: $small-spacing $medium-spacing;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $action-color;
    }
  }
}

.button--primary{
  box-shadow: $shadow-normal;
  color: $white;
  transition: $base-duration--fast $base-timing;
  transition-property: background-color, box-shadow, transform;

  &:focus {
    background-color: $_button-background-color-hover;
    box-shadow: $shadow-hover;
    color: $white;
    transform: translateY(-2px);
  }

  &:hover {
    background-color: $_button-background-color-hover;
    box-shadow: $shadow-hover;
    color: $white;
    transform: translateY(-2px);
  }

  &:active{
    outline: 0;
    background-color: $_button-background-color-active;
    transform: translateY(1px);
    box-shadow: $shadow-active;
  }
}

.button--try {
  border: 0.07em solid $action-color;
  background-color:transparent;
  color:$action-color;
  transition: $base-duration--fast $base-timing;
  transition-property: border-color, color;


  &:focus {
    color: $_button-background-color-hover;
    border-color: $_button-background-color-hover;
  }

  &:hover {
    color: $_button-background-color-hover;
    border-color: $_button-background-color-hover;
  }

  &:active{
    outline: 0;
    }
}


.button--text{
  background-color:transparent;
  border: 0;
  color:$dark-gray;
  padding: 0.4em;
  transition: $base-duration--fast $base-timing;
  transition-property: color;
  text-transform: inherit;

  &:focus {
      background-color: inherit;
      color: $action-color;
  }

  &:hover {
    background-color: inherit;
    color: $action-color;
  }

  &:active {
    outline: 0;
  }
}

.button--danger{
  background-color: $danger-red;
  box-shadow: $shadow-normal;
  color: $white;
  transition: $base-duration--fast $base-timing;
  transition-property: background-color, box-shadow, transform;

  &:focus {
    background-color: $_button-background-color-danger-hover;
    box-shadow: $shadow-hover;
    color: $white;
    transform: translateY(-2px);
  }

  &:hover {
    background-color: $_button-background-color-danger-hover;
    box-shadow: $shadow-hover;
    color: $white;
    transform: translateY(-2px);
  }

  &:active{
    background-color: $_button-background-color-danger-active;
    outline: 0;
    transform: translateY(1px);
    box-shadow: $shadow-active;
  }
}

.button--hamburger {
  background-color:transparent;
  color:$dark-gray;
  // transition: color $base-duration $base-timing;

  &:focus {
      background-color: inherit;
      color: $action-color;
  }

  &:hover {
    background-color: inherit;
    color: $action-color;
  }

  &:active {
    outline: 0;
  }
}
