.tooltip--item {
  $base-border-color: gainsboro !default;
  $base-line-height: 1.5em !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $tooltip-background: white;
  $tooltip-color: $base-font-color;
  $tooltip-max-width: 21em;
  $tooltip-arrow-width: 8px;
  $tooltip-shadow: 0 2px 2px silver;
  $tooltip-distance-from-item: 3em;
  $tooltip-arrow-distance-from-box: -1.3em;

  position: relative;

  &:focus,
  &:active .tooltip {
    opacity: 1;
    visibility: visible;
  }

  .tooltip {
    @include position(absolute, 5em null null 0);
    background-color: $tooltip-background;
    background: #fff;
    border-radius: 0.3em;
    box-shadow: $tooltip-shadow;
    color: $tooltip-color;
    line-height: 1.5em;
    margin: 0 auto;
    padding: 1em;
    max-width: $tooltip-max-width;
    opacity: 0;
    text-align: center;
    transition: all $base-duration--fast $base-timing;

    visibility: hidden;
    z-index: 10;

    overflow-y: auto;
    max-height: 60vh;
    overflow-y: auto;


    p {
      color: $base-font-color;
      line-height: $base-line-height;
      margin: 0;
    }

    &.active{
      opacity: 1;
      visibility: visible;
    }

    &.right{
      right: 0;
      left: auto;
    }

        & ul{
          height: 35vh;
          width: 9em;
          text-align: left;
          & li{
            margin-bottom: 1em;
          }
        }
  }

}
