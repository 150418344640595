html {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 100%;
  line-height: $base-line-height;

  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0.5em 0 0;
}

h1 {
  color: $base-header-color;
  font-size: $heading1-font-size;
  font-weight: 700;
  @include grid-media($media--tablet) {
    font-size: $heading1-font-size * 1.1;
  }

  @include grid-media($media--desktop) {
    font-size: $heading1-font-size * 1.2;
  }
}

h2 {
  color: $base-header-color;
  font-weight: 700;
  font-size: $heading2-font-size;
  margin: 2px 0 10px;

  @include grid-media($media--tablet) {
    font-size: $heading2-font-size * 1.2;
  }

  @include grid-media($media--desktop) {
    font-size: $heading2-font-size * 1.4;
  }
}

h3 {
  color: $base-header-color;
  font-weight: 600;
  font-size: $heading3-font-size;
  margin: 2px 0 10px;

  @include grid-media($media--tablet) {
    font-size: $heading3-font-size * 1.2;
  }

  @include grid-media($media--desktop) {
    font-size: $heading3-font-size * 1.4;
  }
}

h4 {
  color: $base-header-color;
  font-weight: 600;
  margin: 2px 0 6px;
}

p {
  font-size: $article-font-size;
  margin: 0 0 0.1em;
  //max-width: 680px;
}

a {
  color: #000;
  text-decoration: none;
  text-decoration-skip: ink;
  transition: color $base-duration--fast $base-timing;

  &:hover {
    color: $action-color;
  }

  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  &.underline{
    text-decoration: underline;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

span{
  font-size: 1em;
}
